import { useState } from "react"
import { URL_BASE } from "../utils/consts"

export default () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const postNews = async (pdfUrls, searchInput) => {
    const token = localStorage.getItem("token")
    try {
      setLoading(true)
      const response = await fetch(URL_BASE + "/gpt/news", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pdfUrls: pdfUrls,
          searchInput,
        }),
      })
      const data = await response.json()
      if (data.error) {
        throw data
      }
      setData(data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return { data, error, loading, postNews }
}
