import React, { useState } from "react"
import { Container } from "@material-ui/core"
import "./styles.css"
import AuthorizedPageWrapper from "../context/AuthorizedPageWrapper"
import { NavbarLayout } from "../layouts/AppLayout"
import { CHAT_NAME } from "../constants/products"
import NewsScrapper from "../components/NewsScrapper"

const ArticleBender = () => {
  const [openChat, setOpenChat] = useState(false)
  return (
    <NavbarLayout
      setOpenChat={setOpenChat}
      openChat={openChat}
      product={"newsSearch"}
    >
      <AuthorizedPageWrapper>
        <App />
      </AuthorizedPageWrapper>
    </NavbarLayout>
  )
}

const App = () => {
  return (
    <Container maxWidth="md">
      <NewsScrapper />
    </Container>
  )
}

export default ArticleBender
