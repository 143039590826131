import React, { useState } from "react"
import {
  TextField,
  Chip,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ReactMarkdown from "react-markdown"
import usePostNewsRead from "../hooks/usePostNewsRead"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  accordion: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}))

const NewsScrapper = () => {
  const classes = useStyles()
  const { data, postNews, loading, error } = usePostNewsRead()
  const [urls, setUrls] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [expanded, setExpanded] = useState(false)
  const [searchInput, setSearchInput] = useState(
    "Obtén la información de este texto, indica motivo de publicación, monto adeudado, personas involucaradas, fecha de publicación, y cualquier otra información relevante"
  )
  const [openModal, setOpenModal] = useState(false)

  const handleAddUrl = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setUrls([...urls, inputValue.trim()])
      setInputValue("")
    }
  }

  const handleDelete = (urlToDelete) => () => {
    setUrls((urls) => urls.filter((url) => url !== urlToDelete))
  }

  const handlePostNews = () => {
    postNews(urls, searchInput)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleOpenDialog = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value)
  }

  return (
    <Grid container justify="center">
      <h1>Análisis Diario Oficial</h1>
      <TextField
        className={classes.input}
        label="Enter URL"
        variant="outlined"
        fullWidth
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleAddUrl}
      />
      {urls.length ? (
        <Paper component="ul" className={classes.root}>
          {urls.map((url, index) => (
            <li key={index}>
              <Chip label={url} onDelete={handleDelete(url)} />
            </li>
          ))}
        </Paper>
      ) : (
        <div />
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={handleOpenDialog}
      >
        Edit Search Input
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handlePostNews}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : "Iniciar Análisis"}
      </Button>
      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}

      {data?.items &&
        data.items.map((item, index) => (
          <Accordion
            key={index}
            className={classes.accordion}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography>{item.url}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ReactMarkdown>{item.content}</ReactMarkdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Editar búsqueda</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.textArea}
            variant="outlined"
            multiline
            rows={10}
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default NewsScrapper
